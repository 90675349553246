import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

//import gsap from "gsap";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import CaseStudies from "./pages/CaseStudies/CaseStudies";
import RecentProjects from "./pages/RecentProjects/RecentProjects";
import Project from "./pages/Project/Project";
import NotFound from "./pages/NotFound/NotFound";
import Directors from "./pages/Directors/Directors";
import Managers from "./pages/Managers/Managers";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

import "./styles/layout.css";
import "./styles/index.css";

const HelloPage = () => <p>Hello page</p>;

class App extends Component {
  constructor(props) {
    super(props);
    this.bannerRef = React.createRef();
  }

  componentDidMount() {
    //  const timeline = gsap.timeline();
    // timeline.to()
  }

  render() {
    return (
      <div className="flex flex-col App font-circular">
        <Navbar />

        <div className="banner__container">
          <p className="banner__text" ref={this.bannerRef}>
            We are ISO 9001:2015 and ISO 14001:2015 Certified
          </p>
        </div>

        <div className="content-wrapper">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/hello" component={HelloPage} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/case-studies" component={CaseStudies} />
            <Route exact path="/recent-projects" component={RecentProjects} />
            <Route exact path="/directors" component={Directors} />
            <Route exact path="/managers" component={Managers} />
            <Route exact path="/project/:slug" component={Project} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
