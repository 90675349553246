import React from 'react';
import directors from '../../data/directors';
import './directors.css';

export default () => (
  <div className="directors">
    <div className="directors__hero">
      <h1 className="font-black text-5xl text-white">Directors</h1>
      <p className="font-normal mt-10 text-center text-white text-lg">
        Our directors, that help drive NCS Tank Cleaning.
      </p>
    </div>

    <div className="py-12 px-8 lg:px-32">
      {directors.map((director, key) => (
        <div className="director-profile" key={key}>
          <div className="director-image">
            <img src={director.image} alt="Director" />
          </div>

          <div>
            <h1>{director.name}</h1>
            <h2>{director.role}</h2>
            {director.profile}
          </div>
        </div>
      ))}
    </div>
  </div>
);
