import React, { Component } from "react";
import Slider from "../../components/Slider/Slider";
import { getProjects } from "../../contentful";
import staticProjects from "../../data/projects";

import "./recentProjects.css";

export default class RecentProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const projects = await getProjects();
    if (projects) {
      this.setState({ projects, loading: false });
    } else {
      // Fallback to static projects
      this.setState({ projects: staticProjects, loading: false });
    }
  }

  render() {
    return (
      <div className="recent-projects">
        <div className="hero flex flex-col items-center justify-center py-16">
          <h1 className="mt-8 md:mt-0 text-center font-bold text-5xl text-white">
            Recently Completed Projects
          </h1>
          <p className="font-normal text-center leading-normal text-white w-2/3 text-xl mt-8">
            Here's a collection of some our recently completed projects as well
            as pictures of some memorable moments.
          </p>
        </div>

        <div className="px-8 md:px-10 py-16 md:py-24 lg:py-30">
          {this.state.loading ? (
            <p>Loading projects...</p>
          ) : (
            <Slider projects={this.state.projects} />
          )}
        </div>
      </div>
    );
  }
}
