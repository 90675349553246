import React from 'react';
export default [
  {
    name: 'Mr. Ekundayo Ian Johnson',
    role: 'Managing Director',
    image:
      'https://res.cloudinary.com/chudi/image/upload/v1549146973/ncs-tank/directors/20181009_PORTRAIT_NCS_PFP_0002.jpg',
    profile: (
      <p>
        Mr. Ekundayo Johnsons has a B. Eng. degree in Electronics Engineering
        from the University of Middlesex and has worked in various companies in
        the UK, US and Nigeria as a Systems Engineer specializing in computer
        languages and wireless connectivity. Currently, he is the Internal
        Control Director of quality control and monitoring of standards at
        Nigeria Cleaning Service, Managing Director at NCS Tank Cleaning
        Limited. He has excellent track record for managing startup companies.
      </p>
    ),
  },
  {
    name: 'Mr. Innocent C Iroanya',
    role: 'General Manager',
    image:
      'https://res.cloudinary.com/chudi/image/upload/v1549146973/ncs-tank/directors/20181009_PORTRAIT_NCS_PFP_0001.jpg',
    profile: (
      <p>
        He joined the company in the year 2006 as an admin officer and over the
        years has risen the position of General Manager in charge of admin and
        operations. He holds a Bachelor of Science Degree in Business Management
        from the University of Calabar and a Masters Degree in Human Resource
        Management from Lagos State University. An astute project manager who
        has successfully managed the company’s tank cleaning operations over the
        years.
      </p>
    ),
  },
  {
    name: 'Abiola Ojerinde',
    role: 'Senior Technical Supervisor',
    image:
      'https://www.porcelaingres.com/img/collezioni/JUST-BEIGE/big/just_beige_light_brown.jpg',
    profile: (
      <p>
        Abiola Ojerinde enlisted into the Nigerian Navy in the year 1988. He
        rose through the file and rank until the year 2006 when he voluntarily
        retired. While he was servicing in the Nigerian Navy, he picked up
        interest in Engineering; due to his quest and enthusiasm for knowledge
        he was opportuned to serve under various platforms in the Navy like NNS
        Aradu, NNS Beecroft, NNS Erin Omi, NNS Delta to mention but a few. He is
        a well rounded mechanical engineer with also good knowledge in Electrics
        as well. He joined NCS Tank Cleaning in the year 2008 as a technical
        crew member and currently holds the position of Senior Technical
        Supervisor.
      </p>
    ),
  },
];
