export default [
  {
    number: '01',
    client: 'Tema Oil Refinery - Ghana',
    tankSize: '370,000 bbl, Ø = 60 m',
    tankContent: 'Bonny Light Crude Oil',
    sludgeVolume: '7.648 bbl (2%)',
    mobilization: '8',
    blaboInUse: '9',
    demobilization: '5',
    recoveryRate: 'Hydrocarbon = 99.02%',
    recoveredOil: '7,573 bbls',
    valueofRecoveredOil: '$946,625 USD',
    calculation: '$125 x 7,573 = $946,625 USD',
  },
  {
    number: '02',
    client: 'NAOC Brass Island Terminal - Nigeria',
    tankSize: '515,000 bbls',
    tankContent: 'Crude Oil',
    sludgeVolume: '63,229 bbls',
    mobilization: '9',
    blaboInUse: '14',
    demobilization: '6',
    recoveryRate: 'Hydrocarbon - 99.3%',
    recoveredOil: '62,855 bbls',
    valueofRecoveredOil: '$4,399,821 USD',
    calculation: '$70 x 62,855.585 = $4,399,821 USD',
  },

  {
    number: '03',
    client: 'ExxonMobil - QIT',
    tankNumbers: '6701 & 6702',
    tankSize: '670,000 bbls x 2',
    tankContent: 'Crude Sludge, Condensate Sludge, Debris from Fire',
    sludgeVolume: '16,640 + 43,980 bbls = 60,620bbls ',
    mobilization: '9 + 4',
    blaboInUse: '88',
    demobilization: '6 + 8',
    recoveryRate: 'Hydrocarbon = 99%',
    recoveredOil: '60,014 bbls',
    valueofRecoveredOil: '$2,700,000 USD',
    averageCrudePrice: '$45 USD',
  },
  {
    number: '04',
    client: 'Chevron Nigeria Limited, Escravos Island Terminal - Nigeria',
    tankNumbers: '2 & 8',
    tankSize: '200,000 bbls + 400,000 bbls ',
    tankContent: 'Crude Oil',
    sludgeVolume: '1.53meters + 1.310meters',
    mobilization: '10 + 13',
    blaboInUse: '34 + 50',
    demobilization: '7 + 9',
    recoveredOil: '19,865bbls + 40,614.92bbls',
    valueofRecoveredOil: '$3,144,955.84 USD',
    calculation: '$52 x 60,479.92 = $3,144,955.84 USD',
  },
];
