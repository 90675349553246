import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./home.css";
import { clients, partners } from "../../data/clientsPartners";

export default class Home extends Component {
  render() {
    return (
      <div className="home">
        <div className="hero flex flex-col items-center justify-center py-16 px-4">
          <h1 className="mt-10 md:mt-4 lg:mt-0 text-center font-black text-5xl text-white">
            NCS Tank Cleaning Limited
          </h1>
          <p className="font-normal text-center leading-normal text-white md:w-2/3 text-lg lg:text-xl mt-4">
            NCS Tank Cleaning solutions make tank cleaning faster and safer
            while ensuring the highest achievable cleaning quality. With reduced
            cleaning time, minimal consumption of valuable resources such as
            water, chemicals and energy, our solutions cut operating expense and
            increase productivity for our customers, concurrently improving
            safety, as there is no need for physical human entry into the tank.
          </p>
        </div>

        <div className="py-10 px-6 md:py-30 md:px-30 bg-grey-lighter">
          <h1 className="text-center md:text-left text-4xl font-black text-teal-darker leading-custom md:1/2">
            About NCS Tank Cleaning
          </h1>
          <p className="mt-6 md:mt-8 mb-8 md:mb-12 font-normal text-base md:text-lg leading-loose tracking-normal lg:w-3/5 text-teal-darkest">
            NCS Tank Cleaning operations are environmentally friendly,
            minimizing waste disposals and hydrocarbons emissions into the air,
            eliminating human entry and recovering up to 95% of the
            hydrocarbons. <br />
            Personnel are specially trained by our international affiliates,
            Oreco A/S of Denmark, in order to ensure that the process complies
            with increasingly demanding legal and safety requirements.
          </p>

          <NavLink
            to="/about"
            className="no-underline bg-green-dark py-4 px-8 text-base cursor-pointer inline-block text-center rounded text-white"
          >
            Learn more
          </NavLink>
        </div>

        <div className="company-values py-10 px-6 md:py-30 md:px-30 flex flex-col items-center lg:items-stretch lg:flex-row justify-between">
          <div className="w-full mb-10 lg:mb-0 lg:w-1/3 shadow-lg rounded bg-white lg:mr-10 p-8">
            <div className="icon-container">
              <img
                alt="Vision Icon"
                src="https://res.cloudinary.com/chudi/image/upload/v1542702131/ncs-tank/assets/vision.svg"
              />
            </div>
            <h1 className="mt-8 text-teal-darkest font-bold text-3xl">
              Our Vision
            </h1>
            <p className="mt-4 text-teal-darkest font-normal leading-loose tracking-normal text-base">
              To be the preferred provider of excellent comprehensive tank
              cleaning services in Nigeria.
            </p>
          </div>
          <div className="w-full mb-10 lg:mb-0 lg:w-1/3 shadow-lg rounded bg-white lg:mr-10 p-8">
            <div className="icon-container">
              <img
                alt="People Icon"
                src="https://res.cloudinary.com/chudi/image/upload/v1542702129/ncs-tank/assets/people.svg"
              />
            </div>
            <h1 className="mt-8 text-teal-darkest font-bold text-3xl">
              Our People
            </h1>
            <p className="mt-4 text-teal-darkest font-normal leading-loose tracking-normal text-base">
              Excellent productivity we believe is closely related to excellent
              management.
            </p>
          </div>
          <div className="w-full lg:w-1/3 shadow-lg rounded bg-white p-8">
            <div className="icon-container">
              <img
                alt="Policy Icon"
                src="https://res.cloudinary.com/chudi/image/upload/v1542702139/ncs-tank/assets/policy.svg"
              />
            </div>
            <h1 className="mt-8 text-teal-darkest font-bold text-3xl">
              Our Policy
            </h1>
            <p className="mt-4 text-teal-darkest font-normal leading-loose tracking-normal text-base">
              We are committed to proper design and training of our personnel
              and offering services that are demonstrably protective of human
              health and the environment.
            </p>
          </div>
        </div>

        <div className="py-10 px-6 md:py-30 md:px-30 bg-grey-lighter">
          <h1 className="text-center md:text-left text-4xl md:text-5xl w-full lg:w-2/5 font-black text-teal-darker">
            Certifications
          </h1>
          <p className="mt-6 md:mt-8 font-normal text-base md:text-lg leading-loose tracking-normal lg:w-3/5 text-teal-darkest">
            We ensure we comply to standards and follow industry best practices
            in order to provide optimal service to our clients and partners.
          </p>

          <div className="mt-6 md:mt-12 flex flex-col lg:flex-row">
            <img
              src="https://res.cloudinary.com/chudi/image/upload/v1592371254/ncs-tank/certificates/1._NCS_TANK_CLEANING_LIMITED_QMS.png"
              alt="QMS Certificate"
              className="w-full lg:w-1/3 flex-initial lg:mr-32 mb-8 lg:mb-0"
            />

            <img
              src="https://res.cloudinary.com/chudi/image/upload/v1592371254/ncs-tank/certificates/2._NCS_TANK_CLEANING_LIMITED_EMS.png"
              alt="EMS Certificate"
              className="w-full lg:w-1/3 flex-initial"
            />
          </div>
        </div>

        <div className="py-10 px-6 md:py-30 md:px-30">
          <h1 className="text-center md:text-left text-4xl font-black text-teal-darker leading-custom md:1/2 lg:w-2/5">
            Clients
          </h1>

          <div className="logo-container">
            {clients.map((client, index) => (
              <img
                src={client.logo}
                alt={`${client.name} Logo`}
                key={index}
                className={client.name}
              />
            ))}
          </div>
        </div>

        <div className="bg-grey-lighter py-10 px-6 md:py-30 md:px-30">
          <h1 className="text-center md:text-left text-4xl font-black text-teal-darker leading-custom md:1/2 lg:w-2/5">
            Partners
          </h1>

          <div className="logo-container">
            {partners.map((partner, index) => (
              <img
                src={partner.logo}
                alt={`${partner.name} Logo`}
                key={index}
                className={partner.name}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
