import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";

const CardContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

const Card = styled.div`
  background: #ffdbb7;
  background-image: ${(props) => `url('${props.backgroundImage}')`};
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  transition: 0.5s ease-in-out;

  h1 {
    font-size: 2rem;
    z-index: 3;
    text-align: center;
    position: absolute;
    width: 100%;
    margin-top: 30px;
  }

  p {
    position: absolute;
    z-index: 3;
    font-size: 1.15rem;
    bottom: 40px;
    margin-left: 30px;
    a {
      color: white;
      text-decoration: none;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 40%;
      height: 2px;
      border: 1.7px solid white;
      transition: 0.5s ease-in-out;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
`;

const ProjectCard = ({ children, slug, ...props }) => (
  <CardContainer onClick={(e) => props.history.push(`/project/${slug}`)}>
    <Card {...props}>
      {children}
      <p>
        <Link to={`/project/${slug}`}>View more pictures</Link>
      </p>
    </Card>
    <Overlay />
  </CardContainer>
);

export default withRouter(ProjectCard);
