import axios from "axios";

const space_id = "hxxfl5wezhvt";
const environment_id = "master";
const access_token = "6nBeOnu-wPiSvdoDKA9L8GBfWW0sdQ4hNcUrY7EY4_g"; // These are read-only tokens

export const getProjects = async () => {
  try {
    const response = await axios.get(
      `https://cdn.contentful.com/spaces/${space_id}/environments/${environment_id}/entries/?access_token=${access_token}&content_type=project`
    );
    const projects = transformData(response);

    return projects;
  } catch (err) {
    console.log(err);
  }
};

export const getProject = async (slug) => {
  try {
    const response = await axios.get(
      `https://cdn.contentful.com/spaces/${space_id}/environments/${environment_id}/entries/?access_token=${access_token}&content_type=project&fields.slug=${slug}`
    );

    const projects = transformData(response);

    const isNullResult = !projects;
    const isEmptyResult = Array.isArray(projects) && projects.length === 0;
    const isNotArray = !Array.isArray(projects);

    if (isNullResult || isEmptyResult || isNotArray) {
      return null;
    }

    return projects[0];
  } catch (err) {
    console.log(err);
  }
};

/**
 * Transform data from Contentful into a format that is easier to work with
 */
function transformData(input) {
  const outArray = [];
  const entries = input.data.items;
  const assets = input.data.includes.Asset;
  const images = input.data.includes.Entry;

  for (let entry of entries) {
    const transformedItem = {
      slug: entry.fields.slug,
      name: entry.fields.name,
      cover:
        "https:" +
        assets.find((asset) => asset.sys.id === entry.fields.cover.sys.id)
          .fields.file.url,
      images: entry.fields.images.map((img) => {
        const imageEntry = images.find((i) => i.sys.id === img.sys.id);
        const imageUrl =
          "https:" +
          assets.find(
            (asset) => asset.sys.id === imageEntry.fields.image.sys.id
          ).fields.file.url;
        return {
          image: imageUrl,
          caption: imageEntry.fields.caption,
        };
      }),
    };
    outArray.push(transformedItem);
  }

  return outArray;
}
