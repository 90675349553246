import React, { Component } from "react";
import "./about.css";

export default class About extends Component {
  render() {
    return (
      <div className="about">
        <div className="hero flex flex-col items-center justify-center py-16">
          <h1 className="font-black text-5xl md:text-6xl text-center text-white">
            About Us
          </h1>
        </div>

        <div className="py-10 px-6 md:p-16 lg:p-30">
          <h1 className="text-center md:text-left text-4xl md:text-5xl w-full lg:w-2/5 font-black text-teal-darker">
            History
          </h1>
          <p className="mt-6 md:mt-8 font-normal text-base md:text-lg leading-loose tracking-normal lg:w-3/5 text-teal-darkest">
            NCS Tank Cleaning has been operating since 2004 as a pioneer
            automated tank cleaning company in the Oil and Gas industry.
            <br />
            The company is the vision and brain child of Nigeria Cleaning
            Service (NCS) to extend its over half a millenium of janitorial
            services to Corporate and Non-Corporate organizations in Nigeria,
            and to the oil and gas industry; with a strong group of partners we
            created NCS Tank Cleaning in 2004 to cater for this service.
            <br />
            NCS Tank Cleaning Ltd. was established in order to provide tank
            cleaning services to the oil and petrochemical industry using mobile
            and fully integrated process systems, offering simultaneous tank
            cleaning and separation of the cleaned out materials, using the
            'Oreco BLABO' technology.
          </p>
        </div>

        <div className="bg-grey-lighter py-10 px-6 md:p-16 lg:p-30">
          <h1 className="text-left text-4xl md:text-5xl lg:-mt-2 font-black text-teal-darker">
            Our Mission
          </h1>

          <div className="mt-6 md:mt-8 flex flex-col md:flex-row items-start">
            <div className="md:w-3/5">
              <ul className="pl-6 md:px-8 list-reset">
                <li className="font-normal text-base md:text-lg leading-normal tracking-normal text-teal-darkest mb-8">
                  To provide services that meet the needs of our customers and
                  consumers that are demonstratively better in function or value
                  than those of our competitors.
                </li>
                <li className="font-normal text-base md:text-lg leading-normal tracking-normal text-teal-darkest my-8">
                  To actively research, develop and implement technologies that
                  provide improvements in the delivery of our services.
                </li>
                <li className="font-normal text-base md:text-lg leading-normal tracking-normal text-teal-darkest my-8">
                  To operate in a manner designed to minimize environmental,
                  health or safety hazards. We commit ourselves to protect our
                  employees, our employers and others in the vicinity of our
                  operations by employing responsible operating procedures.
                </li>
                <li className="font-normal text-base md:text-lg leading-normal tracking-normal text-teal-darkest my-8">
                  To act with dedication in the full knowledge that we are part
                  of a larger community.
                </li>
                <li className="font-normal text-base md:text-lg leading-normal tracking-normal text-teal-darkest my-8">
                  To provide the best, professionally managed integrated tank
                  cleaning solution to clients who are serious about their
                  health and operational environment by means of skilled and
                  trained personnel, supported by appropriate technology, in
                  accordance with world-class systems and procedures for tank
                  cleaning and facility management.
                </li>
              </ul>
            </div>

            <div className="hidden md:block w-2/5 md:pl-4 lg:pl-10">
              <img
                src="https://res.cloudinary.com/chudi/image/upload/v1542696127/ncs-tank/company-workers/worker-pressing-machine-2.jpg"
                alt="NCS-TC Mission"
                className="rounded"
              />
            </div>
          </div>
        </div>

        <div className="py-10 px-6 md:p-16 lg:p-30">
          <h1 className="text-center md:text-left text-4xl md:text-5xl lg:-mt-2 font-black text-teal-darker">
            Our Vision
          </h1>

          <div className="mt-6 md:mt-8 flex items-start">
            <div className="md:w-3/5">
              <p className="font-normal text-base md:text-lg leading-loose tracking-normal text-teal-darkest">
                To be the preferred provider of excellent comprehensive tank
                cleaning services in Nigeria.
              </p>
            </div>

            <div className="hidden md:block w-2/5 md:pl-4 lg:pl-10">
              <img
                src="https://res.cloudinary.com/chudi/image/upload/v1542696485/ncs-tank/company-workers/workers-looking-at-book-2.jpg"
                alt="NCS-TC Vision"
                className="rounded"
              />
            </div>
          </div>
        </div>

        <div className="bg-grey-lighter py-10 px-6 md:p-16 lg:p-30">
          <h1 className="text-center md:text-left text-4xl md:text-5xl font-black text-teal-darker">
            Our Values
          </h1>

          <div className="mt-10 md:mt-16 company-values lg:flex flex-wrap">
            <div className="md:block md:mx-auto lg:mx-0 lg:flex flex-col w-full md:w-1/2 lg:w-1/4 shadow-lg rounded bg-white mb-16 lg:mr-24 p-8">
              <div className="icon-container mx-auto flex justify-center">
                <img
                  alt="client icon"
                  src="https://res.cloudinary.com/chudi/image/upload/v1542696618/ncs-tank/assets/like.svg"
                />
              </div>
              <p className="mt-8 text-teal-darkest font-normal leading-normal tracking-normal text-base">
                We believe in putting the client first.
              </p>
            </div>
            <div className="md:block md:mx-auto lg:mx-0 lg:flex flex-col w-full md:w-1/2 lg:w-1/4 shadow-lg rounded bg-white mb-16 lg:mr-24 p-8">
              <div className="icon-container mx-auto flex justify-center">
                <img
                  alt="support icon"
                  src="https://res.cloudinary.com/chudi/image/upload/v1542696619/ncs-tank/assets/support.svg"
                />
              </div>
              <p className="mt-8 text-teal-darkest font-normal leading-normal tracking-normal text-base">
                We are determined to provide unmatched cost-effective service
                levels.
              </p>
            </div>
            <div className="md:block md:mx-auto lg:mx-0 lg:flex flex-col w-full md:w-1/2 lg:w-1/4 shadow-lg rounded bg-white mb-16 p-8">
              <div className="icon-container mx-auto flex justify-center">
                <img
                  alt="solidarity icon"
                  src="https://res.cloudinary.com/chudi/image/upload/v1542696618/ncs-tank/assets/solidarity.svg"
                />
              </div>
              <p className="mt-8 text-teal-darkest font-normal leading-normal tracking-normal text-base">
                We acknowledge that our people are our most important assets.
              </p>
            </div>
            <div className="w-full" />
            <div className="md:block md:mx-auto lg:mx-0 lg:flex flex-col w-full md:w-1/2 lg:w-1/4 shadow-lg rounded bg-white mb-16 lg:mb-0 lg:mr-24 p-8">
              <div className="icon-container mx-auto flex justify-center">
                <img
                  alt="honesty icon"
                  src="https://res.cloudinary.com/chudi/image/upload/v1542696616/ncs-tank/assets/determination.svg"
                />
              </div>
              <p className="mt-8 text-teal-darkest font-normal leading-normal tracking-normal text-base">
                We believe in integrity, honesty, reliability, discipline and
                loyalty.
              </p>
            </div>
            <div className="md:block md:mx-auto lg:mx-0 lg:flex flex-col w-full md:w-1/2 lg:w-1/4 shadow-lg rounded bg-white p-8">
              <div className="icon-container mx-auto flex justify-center">
                <img
                  alt="collaboration icon"
                  src="https://res.cloudinary.com/chudi/image/upload/v1542696615/ncs-tank/assets/collaboration.svg"
                />
              </div>
              <p className="mt-8 text-teal-darkest font-normal leading-normal tracking-normal text-base">
                We are committed to teamwork.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10 px-6 md:p-16 lg:p-30">
          <h1 className="text-center md:text-left text-4xl md:text-5xl lg:-mt-2 font-black text-teal-darker">
            Our Policy
          </h1>

          <div className="mt-6 md:mt-16 flex items-start">
            <div className="md:w-3/5">
              <h1 className="font-bold text-xl md:text-2xl text-teal-darkest mb-4">
                Insurance
              </h1>
              <p className="font-normal text-base md:text-lg leading-loose tracking-normal text-teal-darkest">
                Over and above the usual business risks cover client's
                properties, and are further protected by our liability
                insurance.
              </p>
            </div>

            <div className="hidden md:block w-2/5 pl-10">
              <img
                src="https://res.cloudinary.com/chudi/image/upload/v1542698299/ncs-tank/company-workers/workers-holding-metal.jpg"
                alt="Insurance"
                className="rounded"
              />
            </div>
          </div>

          <div className="mt-6 md:mt-30 flex items-start">
            <div className="md:w-3/5">
              <h1 className="font-bold text-xl md:text-2xl text-teal-darkest mb-4">
                Health & Safety Policy
              </h1>
              <p className="font-normal text-base md:text-lg leading-loose tracking-normal text-teal-darkest">
                We will operate in a manner designed to minimize enviromental
                health or saftey hazard. We are committed to protecting our
                clients' properties, employees and others in the vicinity of our
                operations through proper design and training of our personal
                and offering services that are demonstrably protective of human
                health and the environment.
                <br />
                We are prepared to handle emergencies within the confines of our
                operations.
                <br />
                We will encourage employees to report to management any
                condition which they believe can endanger the environment or
                pose health or safety hazards.
                <br />
                We strive to give all our members of staff access to free
                medical treatment.
              </p>
            </div>
            <div className="hidden md:block w-2/5 pl-10">
              <img
                src="https://res.cloudinary.com/chudi/image/upload/v1542699779/ncs-tank/company-workers/workers-pointing-at-stuff.jpg"
                alt="Health & Safety Policy"
                className="rounded"
              />
            </div>
          </div>

          <div className="mt-6 md:mt-30 flex items-start">
            <div className="md:w-3/5">
              <h1 className="font-bold text-xl md:text-2xl text-teal-darkest mb-4">
                Environmental Protection Policy
              </h1>
              <p className="font-normal text-base md:text-lg leading-loose tracking-normal text-teal-darkest">
                As a family-owned company of more than forty six years, which
                has successfully marketed cleaning services over the years, we
                believe in respecting and protecting the environment.
                <br />
                For us, this concern derives directly from the basic principles
                that have guided us from the beginning. Our objective always
                extended beyond financial growth to include broader social
                goals. Foremost among these is promoting health and well being
                of families; not only the families of today, but also the
                families of tomorrow.
              </p>
            </div>
            <div className="hidden md:block w-2/5 pl-10">
              <img
                src="https://res.cloudinary.com/chudi/image/upload/v1542699586/ncs-tank/company-workers/workers-flipping-switch.jpg"
                alt="Environmental Protection Policy"
                className="rounded"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
