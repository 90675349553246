import React from 'react';
import { NavLink } from 'react-router-dom';
import './notFound.css';

export default () => (
  <div className="not-found">
    <h1 className="text-green-dark font-black">404</h1>
    <p className="my-10 text-green-darkest font-bold lg:w-1/2 leading-normal text-xl text-center">
      We can't find the page you're looking for. To find out about how NCS Tank
      Cleaning solves the problem of cleaning Tanks in Nigeria, please head back
      to our home page
    </p>
    <NavLink
      to="/"
      className="no-underline bg-green-dark py-4 px-8 text-base cursor-pointer text-center rounded text-white"
    >
      Back to Homepage
    </NavLink>
  </div>
);
