import React, { Component } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Logo from '../../logo.svg';
import './navbar.css';

class Navbar extends Component {
  state = { showDropdownMenu: false };

  toggleDropdownMenu = () => {
    this.setState({ showDropdownMenu: !this.state.showDropdownMenu });
  };

  /**
   * Very hack way for figuring out if we're either on the director or managers page
   */
  onTeamPage = () =>
    ['/directors', '/managers'].includes(this.props.location.pathname);

  componentWillReceiveProps({ location }) {
    if (this.props.location.pathname !== location.pathname) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      if (this.state.showDropdownMenu)
        this.setState({ showDropdownMenu: false });
    }
  }

  render() {
    return (
      <nav className="navbar flex flex-wrap md:flex-no-wrap fixed z-10 items-center py-1 px-4 md:px-6 pin-t w-full">
        <div className="lg:ml-6 mr-auto">
          <Link to="/" className="list-reset">
            <img src={Logo} alt="Brand Logo" className="brand-logo" />
          </Link>
        </div>

        <div className="block mt-2 md:hidden">
          <label className="menu-icon inline-block p-4 cursor-pointer">
            <input
              className="menu-btn hidden"
              type="checkbox"
              checked={this.state.showDropdownMenu}
              onChange={this.toggleDropdownMenu}
            />
            <span className="navicon" />
          </label>
        </div>

        <div
          className={`w-full md:w-auto mx-auto md:mx-0 py-2 md:py-0 md:block ${
            this.state.showDropdownMenu ? 'block' : 'hidden'
          }`}
        >
          <ul className="md:flex items-center list-reset md:mr-4 lg:mr-8">
            <li className="mx-auto md:mx-4 lg:mx-8 text-center w-1/2 md:w-auto md:text-left py-6 md:py-0">
              <NavLink
                to="/"
                exact
                className="nav-link relative no-underline text-black font-bold text-base"
                activeClassName="active"
              >
                Home
              </NavLink>
            </li>
            <li className="mx-auto md:mx-4 lg:mx-8 text-center w-1/2 md:w-auto md:text-left py-6 md:py-0">
              <NavLink
                to="/about"
                className="nav-link relative no-underline text-black font-bold text-base"
                activeClassName="active"
              >
                About
              </NavLink>
            </li>
            <li className="mx-auto md:mx-4 lg:mx-8 text-center w-1/2 md:w-auto md:text-left py-6 md:py-0">
              <NavLink
                to="/case-studies"
                className="nav-link relative no-underline text-black font-bold text-base"
                activeClassName="active"
              >
                Case Studies
              </NavLink>
            </li>

            <li className="team-dropdown mx-auto md:mx-4 lg:mx-8 text-center w-1/2 md:w-auto md:text-left py-6 md:py-0">
              <div
                className={`team-dropdown__text ${this.onTeamPage() &&
                  'active'} flex justify-center items-center cursor-pointer text-black font-bold text-base`}
              >
                Our Team
                <img
                  src="https://res.cloudinary.com/chudi/image/upload/v1551960083/ncs-tank/assets/chevron-down_1.svg"
                  alt="Chevron"
                  className="chevron"
                />
              </div>

              <div className="team-dropdown__content">
                <ul>
                  <li>
                    <NavLink
                      to="/directors"
                      className="relative no-underline text-black font-bold text-base"
                    >
                      Directors
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/managers"
                      className="relative no-underline text-black font-bold text-base"
                    >
                      Managers
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            <li className="mx-auto md:mx-4 lg:mx-8 text-center w-1/2 md:w-auto md:text-left py-6 md:py-0">
              <NavLink
                to="/recent-projects"
                className="nav-link relative no-underline text-black font-bold text-base"
                activeClassName="active"
              >
                Recent Projects
              </NavLink>
            </li>
            <li className="mx-auto md:ml-4 lg:mx-8 text-center w-1/2 md:w-auto md:text-left py-6 md:py-0">
              <NavLink
                to="/contact"
                className="nav-link relative no-underline text-black font-bold text-base"
                activeClassName="active"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);
