import React from 'react';
import data from '../../data';
import './caseStudies.css';

export default props => (
  <div className="case-studies">
    <div className="hero flex flex-col items-center justify-center py-16">
      <h1 className="mt-8 md:mt-0 text-center font-bold text-5xl text-white">
        Case Studies
      </h1>
      <p className="font-normal text-center leading-normal text-white w-2/3 text-xl mt-8">
        Here's a list of case studies based on previous projects, as well as
        insights and findings we gained during the course of executing these
        projects.
      </p>
    </div>

    <div className="study-container py-10 px-6 md:p-30">
      {data.map((data, key) => (
        <div
          className="study-card w-full md:w-3/4 shadow-lg py-4 px-8 rounded-lg"
          key={key}
        >
          <h1 className="text-green-light tracking-normal opacity-50 font-bold text-right text-3xl md:text-4xl">
            {data.number}
          </h1>
          <h1 className="mt-4 leading-normal text-grey-dark font-bold text-left text-2xl md:text-3xl">
            {data.client}
          </h1>
          <p className="mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">Licensee:</span>
            NCS Tank Cleaning Ltd.
          </p>
          {data.tankNumbers && (
            <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
              <span className="text-grey mr-2">Tank Numbers:</span>
              {data.tankNumbers}
            </p>
          )}
          <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">Tank Size:</span>
            {data.tankSize}
          </p>
          <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">Tank Content:</span>
            {data.tankContent}
          </p>
          <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">Sludge Volume:</span>
            {data.sludgeVolume}
          </p>
          <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">Mobilization:</span>
            {data.mobilization} days
          </p>
          <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">BLABO in Use:</span>
            {data.blaboInUse} days
          </p>
          <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">De-mobilization:</span>
            {data.demobilization} days
          </p>
          {data.recoveryRate && (
            <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
              <span className="text-grey mr-2">Recovery Rate:</span>
              {data.recoveryRate}
            </p>
          )}
          <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">Recovered Oil:</span>
            {data.recoveredOil}
          </p>
          <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
            <span className="text-grey mr-2">Value of Recovered Oil:</span>
            {data.valueofRecoveredOil}
          </p>
          {data.averageCrudePrice && (
            <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
              <span className="text-grey mr-2">Average Price of Crude:</span>
              {data.averageCrudePrice}
            </p>
          )}
          {data.calculation && (
            <p className="mt-2 md:mt-4 leading-loose tracking-normal text-teal-darkest font-normal text-left text-base md:text-lg">
              <span className="text-grey mr-2">Calculation:</span>
              {data.calculation}
            </p>
          )}
        </div>
      ))}
    </div>
  </div>
);
