import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from '../../logo.svg';

const date = new Date();
const year = date.getFullYear();

const FooterLogo = styled.img`
  margin: 0 auto;
  display: block;
  margin-top: 2rem;
  filter: grayscale(80%);
  width: 80px;
  height: 80px;
`;

export default () => (
  <div className="mx-6 md:mx-16 my-10 flex-no-shrink border-t border-grey-light">
    <FooterLogo src={Logo} alt="Footer logo" />

    <div className="flex mt-6 justify-center px-4 md:px-10 text-grey">
      <Link
        to="/about"
        className="text-base md:text-lg text-grey no-underline mx-4 md:mx-12"
      >
        About
      </Link>
      <Link
        to="/contact"
        className="text-base md:text-lg text-grey no-underline mx-4 md:mx-12"
      >
        Contact
      </Link>
    </div>

    <p className="mt-10 text-sm text-center text-grey">
      © {year} NCS Tank Cleaning Limited. All rights reserved.
    </p>
  </div>
);
