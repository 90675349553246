export const clients = [
  {
    name: 'AGIP',
    logo:
      'https://res.cloudinary.com/chudi/image/upload/q_auto:low/v1542702130/ncs-tank/assets/Agip.jpg',
  },
  {
    name: 'Exxon Mobile',
    logo:
      'https://res.cloudinary.com/chudi/image/upload/q_auto:low/v1542702130/ncs-tank/assets/exxonmobile.png',
  },
  {
    name: 'Chevron',
    logo:
      'https://res.cloudinary.com/chudi/image/upload/q_auto:low/v1542702142/ncs-tank/assets/Chevron_Logo.svg.png',
  },
  {
    name: 'TOR',
    logo:
      'https://res.cloudinary.com/chudi/image/upload/q_auto:low/v1552426220/ncs-tank/assets/tor.png',
  },
];

export const partners = [
  {
    name: 'Oreco',
    logo:
      'https://res.cloudinary.com/chudi/image/upload/q_auto:low/v1542702138/ncs-tank/assets/oreco-logo.png',
  },
  {
    name: 'Entecx',
    logo:
      'https://res.cloudinary.com/chudi/image/upload/v1551953023/ncs-tank/assets/entecx_logo_500.jpg',
  },
];
