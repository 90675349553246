import React from 'react';
export default [
  {
    name: 'Mr. Ekundayo Ian Johnson',
    role: 'Managing Director',
    image:
      'https://res.cloudinary.com/chudi/image/upload/v1549146973/ncs-tank/directors/20181009_PORTRAIT_NCS_PFP_0002.jpg',
    profile: (
      <p>
        Mr. Ekundayo Johnsons has a B. Eng. degree in Electronics Engineering
        from the University of Middlesex and has worked in various companies in
        the UK, US and Nigeria as a Systems Engineer specializing in computer
        languages and wireless connectivity. Currently, he is the Internal
        Control Director of quality control and monitoring of standards at
        Nigeria Cleaning Service, Managing Director at NCS Tank Cleaning
        Limited. He has excellent track record for managing startup companies.
      </p>
    ),
  },
  {
    name: 'Prince Adekunle Adeyeba',
    role: 'Director in charge of Marketing',
    image:
      'https://res.cloudinary.com/chudi/image/upload/v1549146973/ncs-tank/directors/20181009_PORTRAIT_NCS_PFP_0004.jpg',
    profile: (
      <p>
        Prince Adekunle Adeyeba is an accountant by training but has evolved
        into a seasoned business man and entrepreneur with over 20+ years in the
        Oil and Gas Service Industry. Prince Adeyeba started making a name for
        himself professionally in 90s' when he became country representative for
        business development for two major subsidiaries of Daewoo Corporation,
        one of which was Daewoo Ship Building & Marine Engineering (DSME).
        During his time with Daewoo, he was able to secure several projects for
        the international corporation, the most prominent of them being the
        Agbami FPSO (Floating, Production, Storage & offloading) for Chevron as
        well as other FPSO projects in Angola. With Daewoo Engineering &
        Construction Limited, he served as the company's business development
        director where he was able to secure several turnkey projects which
        includes a 750MW combined cycle power plant for as well as South
        Forcados flow station for Shell. He was also able to secure the QIT
        expansion project for Mobil as well as many others. <br /> <br />
        In addition to his illustrious career with Daewoo, Prince Adeyeba also
        served as the Chairman of LYK Engineering, a company which he
        spearheaded to team up with Akwa Ibom State Government to create Ibom
        Power Company Limited. Together, they were responsible for the
        engineering, construction, commissioning and operation of the 240MW Ibom
        Power Plant. This was Nigeria's first private power company. He was also
        the vice chairman and majority shareholder of the defunct Co-operative
        Bank. In his capacity, he was responsible for the appraisal, evaluation
        and approval of credit facilities for banks customers. Prince Adekunle
        Adeyeba is really a man with many hats and a jack of all trades. He's
        very knowledgeable in different industries and continues to challenge
        himself even till this very day.
      </p>
    ),
  },
  {
    name: 'Mr. George Etomi',
    role: 'Director',
    image:
      'https://res.cloudinary.com/chudi/image/upload/v1549146973/ncs-tank/directors/20181009_PORTRAIT_NCS_PFP_0005.jpg',
    profile: (
      <p>
        George Etomi is a renowned lawyer based in Lagos, Nigeria, and is the
        founder and principal partner at the law firm of George Etomi &
        Partners, which he established in 1984. Mr. Etomi has over 35 years of
        active practice; he has gathered a wealth of experience in diverse areas
        of law and as a result, has contributed immensely to the development of
        corporate/commercial law practice in Nigeria. His practice areas cuts
        across commercial transactions in the fields of Energy and Natural
        Resources Law, Aviation Law, Building and Construction Law, Banking and
        Finance Law, Arbitration, Telecommunications, Legislative Drafting and
        Competition Law and policy. But today, he concentrates in Energy and
        Natural Resources and Aviation Law He attended the University of Ife
        (now Obafemi Awolowo University) where he obtained his Bachelor’s degree
        in Law in 1976. Thereafter, he was called to the Nigerian Bar in 1977.
        He obtained his Masters degree in Law from the London School of
        Economics and Political Science in 1979 and attended the Chief Executive
        Programme of the Lagos Business School in 1998.
      </p>
    ),
  },
  {
    name: 'Adebowale S. Adeyeba',
    role: 'Director',
    image:
      'https://res.cloudinary.com/chudi/image/upload/v1549146976/ncs-tank/directors/20181009_PORTRAIT_NCS_PFP_0003.jpg',
    profile: (
      <p>
        Mr. Adebowale is a Business Administration Graduate from Drexel
        University with concentrations in Marketing, International Business and
        Economics. He is a serial entrepreneur and the founder of Sights and
        Sounds Production, an event production and management company dedicated
        to supporting the entertainment industry with their interesting
        production designs coupled with effective project management skills.
        Since the establishment of Sights and Sounds in 2012, Wale has worked in
        different capacities, one of which includes being the facility manager
        of the iconic Muri Okunola park in Lagos. He was able to transform a
        once neglected and often overlooked public park into one of the most
        sort after event spaces in the city of Lagos today. In addition to
        working with the Lagos State Government, Wale has also used his platform
        to partner with The Beat 99.9FM and Naija FM and has earned a reputation
        as being one of their most reliable production partners since he started
        producing their NYSC concerts in 2012. Even after years of show
        production, Wale still saw it fit to make a move into and establish a
        presence in the agricultural sector. He recently founded Fola Foods, an
        indigenous food production and processing company. Despite being the
        youngest on board, Wale also brings a unique combination of leadership
        and project management experience, public speaking/presentation skills.
        He's dynamic, energetic, reliable and highly dependable.
      </p>
    ),
  },
];
