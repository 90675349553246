import React, { Component } from "react";
import projects from "../../data/projects";
import chunk from "lodash/chunk";
import "./project.css";
import NotFound from "../NotFound/NotFound";
import { getProject } from "../../contentful";

export function fetchProjectFromStaticData(slug) {
  const [project] = projects.filter(
    (project) => project.slug.toLowerCase() === slug.toLowerCase()
  );

  if (!project) return this.setState({ notFound: true });

  this.setState({
    project: { ...project, images: chunk(project.images, 3) },
  });
}

export default class Project extends Component {
  state = {
    project: null,
    notFound: false,
    loading: false,
  };

  async componentDidMount() {
    this.setState({ loading: true });

    const { slug } = this.props.match.params;

    const fetchedProject = await getProject(slug);

    if (!fetchedProject)
      return this.setState({ notFound: true, loading: false });

    this.setState({
      project: { ...fetchedProject, images: chunk(fetchedProject.images, 3) },
      loading: false,
    });
  }

  render() {
    const { project, notFound, loading } = this.state;

    if (notFound) return <NotFound />;

    if (loading || !project) {
      return (
        <div className="project">
          <div className="project__hero">
            <h1 className="font-black text-5xl text-white">Project</h1>
            <p className="font-normal mt-10 text-center text-white text-lg">
              Some moments from our successful execution of a project
            </p>
          </div>

          <div className="mt-20 px-10 md:px-12 lg:px-32">
            Loading Project...
          </div>
        </div>
      );
    }

    return (
      <div className="project">
        <div className="project__hero">
          <h1 className="font-black text-5xl text-white">{project.name}</h1>
          <p className="font-normal mt-10 text-center text-white text-lg">
            Some moments from our successful execution of a project for{" "}
            {project.name}.
          </p>
        </div>

        <div className="mt-20 px-10 md:px-12 lg:px-32">
          {project.images.map((imageRow, key) => (
            <div className="project__row" key={key}>
              {imageRow.map((image, key) => (
                <div className="project__row__child" key={key}>
                  <img src={image.image} alt="Project" />

                  <p className="mt-3 text-grey-darkest text-md md:text-lg leading-normal">
                    {image.caption}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

// 1.1rem;
