import React from 'react';
import managers from '../../data/managers';
import './managers.css';

export default () => (
  <div className="managers">
    <div className="managers__hero">
      <h1 className="font-black text-5xl text-white">Managers</h1>
      <p className="font-normal mt-10 text-center text-white text-lg">
        Our managers, that help drive NCS Tank Cleaning.
      </p>
    </div>

    <div className="py-12 px-8 lg:px-32">
      {managers.map((manager, key) => (
        <div className="manager-profile" key={key}>
          <div className="manager-image">
            <img src={manager.image} alt="Manager" />
          </div>

          <div>
            <h1>{manager.name}</h1>
            <h2>{manager.role}</h2>
            {manager.profile}
          </div>
        </div>
      ))}
    </div>
  </div>
);
