import React from "react";
import Slider from "react-slick";
import ProjectCard from "./ProjectCard";
import LeftChevron from "./left-chevron.svg";
import RightChevron from "./right-chevron.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LeftArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{
        ...style,
        display: "block",
        width: "35px",
        height: "35px",
        zIndex: 100,
      }}
      onClick={onClick}
      src={LeftChevron}
      alt="Arrow"
    />
  );
};

const RightArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{
        ...style,
        display: "block",
        width: "35px",
        height: "35px",
      }}
      onClick={onClick}
      src={RightChevron}
      alt="Arrow"
    />
  );
};

const settings = {
  dots: false,
  infinite: false,
  speed: 700,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: <LeftArrow />,
  nextArrow: <RightArrow />,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export default class ProjectsSlider extends React.Component {
  render() {
    return (
      <div>
        <Slider {...settings}>
          {this.props.projects.map((data, key) => (
            <ProjectCard
              key={key}
              backgroundImage={data.cover}
              slug={data.slug}
            >
              <h1>{data.name}</h1>
            </ProjectCard>
          ))}
        </Slider>
      </div>
    );
  }
}
