import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { HalfCircleSpinner } from 'react-epic-spinners';
import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import isPhone from 'validator/lib/isMobilePhone';
import axios from 'axios';

import './contact.css';

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={18} defaultCenter={{ lat: 6.53505, lng: 3.34808 }}>
      <Marker position={{ lat: 6.53505, lng: 3.34808 }} />
    </GoogleMap>
  ))
);

export default class Contact extends Component {
  defaultFormState = {
    value: '',
    error: false,
  };

  state = {
    email: { ...this.defaultFormState },
    message: { ...this.defaultFormState },
    name: { ...this.defaultFormState },
    phone: { ...this.defaultFormState },
    subject: { ...this.defaultFormState },
    loading: false,
    toast: {
      show: true,
      context: '',
      message: '',
    },
  };

  showToast = (context, message, timeout = 5500) => {
    this.setState({
      toast: {
        show: true,
        context,
        message,
      },
    });

    setTimeout(this.clearToast, timeout);
  };

  clearToast = () =>
    this.setState({
      toast: {
        show: false,
        context: '',
        message: '',
      },
    });

  handleChange = e => {
    const { name, value } = e.target;
    const formField = { ...this.state[name] };
    formField.value = value;
    this.setState({ [name]: formField }, () => {
      switch (name) {
        case 'name':
        case 'subject':
        case 'message':
          const field = { ...this.state[name] };
          if (isEmpty(value)) field.error = true;
          else field.error = false;
          return this.setState({ [name]: field });
        case 'email':
          const email = { ...this.state.email };
          if (isEmpty(value) || !isEmail(value)) email.error = true;
          else email.error = false;
          return this.setState({ email });
        case 'phone':
          const phone = { ...this.state.phone };
          if (isEmpty(value) || !isPhone(value /* 'en-NG' */))
            phone.error = true;
          else phone.error = false;
          return this.setState({ phone });
        default:
          return;
      }
    });
  };

  doesFormHaveErrors = () => {
    const fields = ['email', 'message', 'name', 'phone', 'subject'];

    const errors = fields.filter(
      field => this.state[field].error || !this.state[field].value
    );
    return !!errors.length;
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { email, message, name, phone, subject, loading } = this.state;

    if (loading || this.doesFormHaveErrors()) return;

    this.setState({ loading: true });

    try {
      await axios.post('https://ncstankcleaningmail.now.sh', {
        subject: subject.value,
        name: name.value,
        phone: phone.value,
        email: email.value,
        message: message.value,
      });

      this.setState({
        email: { ...this.defaultFormState },
        message: { ...this.defaultFormState },
        name: { ...this.defaultFormState },
        phone: { ...this.defaultFormState },
        subject: { ...this.defaultFormState },
        loading: false,
      });
      this.showToast(
        'success',
        'Thanks! Your email has been successfully sent'
      );
    } catch (err) {
      this.setState({ loading: false });

      this.showToast(
        'error',
        err.response.data.message ||
          'An unexpected error occured. Please try again.'
      );
    }
  };

  render() {
    const { email, message, name, phone, subject, loading } = this.state;
    return (
      <div className="contact">
        {this.state.toast.show && (
          <div className={`toast ${this.state.toast.context}`}>
            {this.state.toast.message}
          </div>
        )}

        <div className="hero flex flex-col items-center justify-center py-16">
          <h1 className="font-black text-5xl md:text-6xl text-center text-white">
            Contact Us
          </h1>
        </div>

        <div className="py-10 px-6 md:p-16 lg:py-16 lg:px-30 flex flex-col md:flex-row items-start">
          <div className="w-full md:w-2/5 pr-8">
            <p className="text-base md:text-lg mb-6 md:mb-10 leading-custom">
              <span className="text-grey font-bold mr-2">Tel:</span>
              <a
                href="tel:+2349074123330"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline text-teal-darker font-normal"
              >
                +234 907 4123 330,{' '}
              </a>
              <a
                href="tel:+2348111330330"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline text-teal-darker font-normal"
              >
                +234 811 1330 330
              </a>
            </p>

            <p className="text-base md:text-lg mb-6 md:mb-10 leading-custom">
              <span className="text-grey font-bold mr-2">Email:</span>
              {/* <a
                href="mailto:ici@ncstankcleaning.com"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline text-teal-darker font-normal"
              >
                ici@ncstankcleaning.com,{' '}
              </a> */}
              <a
                href="mailto:info@ncstankcleaning.com"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline text-teal-darker font-normal"
              >
                info@ncstankcleaning.com
              </a>
            </p>

            <p className="text-base md:text-lg mb-10 md:mb-0 leading-custom">
              <span className="text-grey font-bold mr-2">Address:</span>
              <span className=" text-teal-darker font-normal">
                90 Ladipo Street, (Cleanjohn House) Matori Mushin Lagos.
              </span>
            </p>
          </div>

          <div className="w-full md:w-3/5">
            <Map
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAxiNtwQxchmRqnf0uodzjZTi_q1wndXA4&libraries=places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div style={{ height: `500px`, width: '100%' }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>

        <div className="py-10 px-6 md:p-16 lg:py-16 lg:px-30 md:w-2/3">
          <h1 className="text-left text-3xl font-black text-teal-darker leading-custom mb-4 md:mb-6 md:w-2/5">
            Enquiries
          </h1>
          <p className="text-left text-base md:text-lg font-normal text-teal-darker leading-custom">
            Please contact us using this form if you have any enquiries about
            NCS Tank Cleaning Limited Services.
          </p>
          <form
            className="py-6 md:py-10 pr-4 md:px-0 contact_form w-full"
            onSubmit={this.handleSubmit}
          >
            <div className="flex justify-between items-center mb-12">
              <input
                type="text"
                className={`p-3 w-1/2 border-2 border-grey-light rounded-lg mr-6 ${name.error &&
                  'has-error'}`}
                placeholder="Name*"
                value={name.value}
                name="name"
                onChange={this.handleChange}
              />

              <input
                type="text"
                className={`p-3 w-1/2 border-2 border-grey-light rounded-lg ${phone.error &&
                  'has-error'}`}
                placeholder="Phone*"
                value={phone.value}
                name="phone"
                onChange={this.handleChange}
              />
            </div>

            <div className="flex justify-between items-center mb-12">
              <input
                type="text"
                className={`p-3 w-1/2 border-2 border-grey-light rounded-lg mr-6 ${email.error &&
                  'has-error'}`}
                placeholder="Email Address*"
                value={email.value}
                name="email"
                onChange={this.handleChange}
              />
              <input
                type="text"
                className={`p-3 w-1/2 border-2 border-grey-light rounded-lg ${subject.error &&
                  'has-error'}`}
                placeholder="Email Subject*"
                value={subject.value}
                name="subject"
                onChange={this.handleChange}
              />
            </div>

            <div>
              <textarea
                className={`mb-12 p-3 w-full leading-normal tracking-normal border-2 border-grey-light rounded-lg ${message.error &&
                  'has-error'}`}
                value={message.value}
                onChange={this.handleChange}
                name="message"
                rows={10}
                placeholder="Message*"
              />
            </div>

            {loading ? (
              <HalfCircleSpinner
                size={55}
                color="#1f9d55"
                className="block mx-auto"
              />
            ) : (
              <input
                type="submit"
                value="Send"
                className="block w-1/2 mx-auto p-4 text-white bg-green-dark hover:bg-green text-center rounded-full cursor-pointer"
                disabled={this.doesFormHaveErrors()}
              />
            )}
          </form>
        </div>
      </div>
    );
  }
}
