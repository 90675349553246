export default [
  {
    slug: "tor",
    name: '"TOR" - Tema Oil Refinery, Ghana',
    cover:
      "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113016/ncs-tank/projects/TOR/TOR4.jpg",
    images: [
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113035/ncs-tank/projects/TOR/TOR14.jpg",
        caption: "A cross section of the tank cleaning equipment.",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113034/ncs-tank/projects/TOR/TOR6.jpg",
        caption: "The hairpin pipe hosted onto the tank",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113034/ncs-tank/projects/TOR/TOR13.jpg",
        caption:
          "500KVA generator for standby supply of power of the equipment",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113033/ncs-tank/projects/TOR/TOR12.jpg",
        caption:
          "Picture of two oil distributors to be used during the project",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113030/ncs-tank/projects/TOR/TOR10.jpg",
        caption:
          "An inspection of the equipment by the chairman of the company",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113024/ncs-tank/projects/TOR/TOR9.jpg",
        caption:
          "An inspection of the equipment by the management team and invited guests",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113022/ncs-tank/projects/TOR/TOR5.jpg",
        caption:
          "A picture of the single nozzle sweeper used for cleaning operation",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113016/ncs-tank/projects/TOR/TOR4.jpg",
        caption: "A walk through of the equipment led by a team member",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113013/ncs-tank/projects/TOR/TOR8.jpg",
        caption: "A walk through of the equipment led by a team member",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113013/ncs-tank/projects/TOR/TOR7.jpg",
        caption: "A cross section of the cleaning crew",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113010/ncs-tank/projects/TOR/TOR1.jpg",
        caption:
          "A cross section of the cleaning crew with its technical partners from Oreco A/S of Denmark",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549113008/ncs-tank/projects/TOR/TOR3.jpg",
        caption: "A picture of the cyclones",
      },
    ],
  },
  {
    slug: "agip",
    name: '"NAOC" - Nigerian Agip Oil Company',
    cover:
      "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125574/ncs-tank/projects/AGIP/Agip7.jpg",
    images: [
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125647/ncs-tank/projects/AGIP/Agip14.jpg",
        caption:
          "Cross section of Blabo operating in a waterlogged environment",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125646/ncs-tank/projects/AGIP/Agip17.jpg",
        caption: "Manway for tank 18",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125634/ncs-tank/projects/AGIP/Agip8.jpg",
        caption: "Access to roof of tank 18",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125635/ncs-tank/projects/AGIP/Agip10.jpg",
        caption: "Tank roof of tank 18",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125634/ncs-tank/projects/AGIP/Agip18.jpg",
        caption: "Tank shell and dyke",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125629/ncs-tank/projects/AGIP/Agip16.jpg",
        caption: "Aerial view of Blabo",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125615/ncs-tank/projects/AGIP/Agip15.jpg",
        caption: "Suction module and hairpin pipe",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125612/ncs-tank/projects/AGIP/Agip5.jpg",
        caption: "Part of tank cleaning equipment",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125600/ncs-tank/projects/AGIP/Agip13.jpg",
        caption: "SNS(Single Nozzle Sweeper) in operation",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125599/ncs-tank/projects/AGIP/Agip11.jpg",
        caption: "Interior view of cleaned tank",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125594/ncs-tank/projects/AGIP/Agip2.jpg",
        caption: "Aerial view of tank cleaning equipment",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125593/ncs-tank/projects/AGIP/Agip12.jpg",
        caption: "Tank interior before final cleaning",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125586/ncs-tank/projects/AGIP/Agip9.jpg",
        caption: "SNS(Single Nozzle Sweeper) in operation",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125574/ncs-tank/projects/AGIP/Agip7.jpg",
        caption: "Cross section of tank cleaning crew",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125573/ncs-tank/projects/AGIP/Agip4.jpg",
        caption: "Access to tank roof",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125569/ncs-tank/projects/AGIP/Agip3.jpg",
        caption: "Interior of cleaned tank",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125567/ncs-tank/projects/AGIP/Agip1.jpg",

        caption: "Cold tapping in progress",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549125566/ncs-tank/projects/AGIP/Agip6.jpg",
        caption: "Interior of cleaned tank",
      },
    ],
  },
  {
    slug: "mobil",
    name: "Mobil",
    cover:
      "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138552/ncs-tank/projects/Mobil/Mobil8.jpg",
    images: [
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138595/ncs-tank/projects/Mobil/Mobil3.jpg",
        caption: "Mobilization of hairpin pipe",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138576/ncs-tank/projects/Mobil/Mobil2.jpg",

        caption: "Mobilization of hairpin pipe",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138557/ncs-tank/projects/Mobil/Mobil7.jpg",

        caption: "Mobilization of hairpin pipe",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138552/ncs-tank/projects/Mobil/Mobil8.jpg",

        caption: "Mobilization of hairpin pipe",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138490/ncs-tank/projects/Mobil/Mobil1.jpg",

        caption: "Mobilization of hairpin pipe",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138489/ncs-tank/projects/Mobil/Mobil4.jpg",

        caption: "Site stabilization",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138453/ncs-tank/projects/Mobil/Mobil6.jpg",

        caption: "Hairpin pipe Mobilization using crane",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549138409/ncs-tank/projects/Mobil/Mobil5.jpg",
        caption: "Flooded site",
      },
    ],
  },
  {
    slug: "chevron",
    name: "Chevron",
    cover:
      "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139264/ncs-tank/projects/Chevron/Chevron8.jpg",
    images: [
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139267/ncs-tank/projects/Chevron/Chevron9.jpg",
        caption: "Access road to site",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139266/ncs-tank/projects/Chevron/Chevron10.jpg",
        caption: "Suction module in operation",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139266/ncs-tank/projects/Chevron/Chevron11.jpg",

        caption: "Improvised walkway due to site waterlogg",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139264/ncs-tank/projects/Chevron/Chevron7.jpg",

        caption: "Cross section of waterlogged site",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139264/ncs-tank/projects/Chevron/Chevron8.jpg",

        caption: "Cross section of site showing skips",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139263/ncs-tank/projects/Chevron/Chevron5.jpg",

        caption: "Cross section of skips for sludge",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139263/ncs-tank/projects/Chevron/Chevron6.jpg",

        caption: "Improvised walkway",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139262/ncs-tank/projects/Chevron/Chevron4.jpg",

        caption:
          "Cross section of site showing skips in waterlogged environment",
      },
      {
        image:
          "https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139262/ncs-tank/projects/Chevron/Chevron2.jpg",

        caption: "Cross section of tank cleaning equipment setup",
      },

      // 'https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139267/ncs-tank/projects/Chevron/Chevron12.jpg',
      // 'https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139262/ncs-tank/projects/Chevron/Chevron3.jpg',
      // 'https://res.cloudinary.com/chudi/image/upload/q_auto/v1549139262/ncs-tank/projects/Chevron/Chevron1.jpg',
    ],
  },
];
